/* line 1, src/views/error/404.scss */
.error-404 {
  align-items: center;
  padding: 60px 30px;
}

/* line 4, src/views/error/404.scss */
.error-404__image {
  max-width: 468px;
}
